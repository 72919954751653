<template>
<div class="fill-height d-flex flex-column">

  <!-- Navigation Header -->
  <div>
    <ws-navigation-header
        style="width: 100%"
        v-model="$store.state.finemailer.navigation"
        :items="navigationSelect"
        no-number
        padding="4px"
        class="my-2"
    />
    <portal-target class="mt-n3" name="block_settings_return"></portal-target>
  </div>

  <!-- Content  -->
  <div class="flex-grow-1 overflow-y-auto">

    <email-settings-general
        v-if="navigation === 'general'"
    />
    <email-settings-block
        v-if="navigation === 'block'"
    />
    <email-settings-element
        v-if="navigation === 'element'"
    />

    <email-settings-column
        v-if="navigation === 'column'"
    />


  </div>


</div>
</template>

<script>
import emailSettingsGeneral
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/editor/settings/emailSettingsGeneral";
import emailSettingsBlock
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/editor/settings/emailSettingsBlock";
import emailSettingsElement
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/editor/settings/emailSettingsElement";
import emailSettingsColumn
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/editor/settings/emailSettingsColumn";
import {mapState} from "vuex";
export default {
  name: "emailEditorSettings",
  components : {
    emailSettingsGeneral,
    emailSettingsBlock,
    emailSettingsElement,
    emailSettingsColumn
  },
  data() {
    return {
    }
  },
  computed : {
    ...mapState('finemailer' , [
      'navigation',
    ]),

    selectedBlockUuid() {
      return this.$store.state.finemailer.selectedBlock.uuid
    },
    selectedElementUuid() {
      return this.$store.state.finemailer.selectedElement.uuid
    },

    navigationSelect() {
      let items = [];

      if ( this.$store.state.finemailer.selectedElement.uuid ) {
        items.push({ text : this.$t('Element') , value : 'element'   })
      }

      if ( this.$store.state.finemailer.selectedBlock.uuid ) {
        items.push({ text : this.$t('Block') , value : 'block' })
      }

      if ( this.$store.state.finemailer.selectedBlock.config &&  this.$store.state.finemailer.selectedBlock.config.columns > 1 ) {
        items.push({ text : this.$t('Column')   , value : 'column'   })
      }

      items.push({ text : this.$t('General') , value : 'general' })

      return items
    },
  }
}
</script>

<style scoped>

</style>