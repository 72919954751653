<template>
  <ws-dialog
      v-model="display"
      :title="$t(`mailer.wizard.settings.receivers.${subtract ? 'subtract' : 'add'}`)"
      @save="handleSave"
      :disable-save="LOADING"
      show-delete
  >
    <template #delete>
      <h5 class="wsACCENT">
        <v-icon small :color="wsACCENT">mdi-account-multiple</v-icon>
        {{ $tc('mailer.wizard.settings.receivers.subscribers_total' , 24) }}
      </h5>
    </template>


    <ws-select
        v-model="entityData.type"
        :label="$t('mailer.wizard.settings.receivers.type.title')"
        :placeholder="$t('mailer.wizard.settings.receivers.type.placeholder')"
        :items="RECEIVERS_GROUP_TYPES_SELECT"
        :class="[{'mb-n10' : ['all','managers'].includes(entityData.type)}]"
    />


    <ws-select
        v-if="entityData.type === 'list'"
        v-model="entityData.contact_list_id"
        :items="contactLists"
        :label="$t('contacts.list.title')"
        :placeholder="$t('contacts.list.placeholder')"
    />

    <ws-select
        v-if="entityData.type === 'course'"
        v-model="entityData.course"
        :items="coursesSelect"
        :label="$t('Course')"
        :placeholder="$t('ChooseCourse')"
    />
    <ws-select
        v-if="receiversGroupFilterCondition"
        v-model="entityData.filter"
        :items="receiversGroupFiltersSelect"
        :label="$t('Filter')"
        :placeholder="$t('ChooseFilter')"
    />

    <!-- SEGMENTATION -->


<!--    <ws-switch-->
<!--        @input="handleSegmentationOn"-->
<!--        v-model="entityData.is_segmented"-->
<!--        placeholder="mailer.wizard.settings.receivers.filters.title"-->
<!--    />-->

<!--    <v-sheet-->
<!--        v-if="entityData.is_segmented "-->
<!--        :color="wsLIGHTCARD"-->
<!--        class="wsRoundedSemi pa-4"-->
<!--    >-->

<!--      <div v-for="(item,i) in entityData.filters || []" :key="i" class="d-flex flex-row">-->
<!--        <ws-select-->
<!--            :items="filtersKeysSelect"-->
<!--            :placeholder="$t('Filter')"-->
<!--            class="mr-4"-->
<!--            width="200px"-->
<!--        />-->
<!--        <ws-select-->
<!--            :items="filtersConditionsSelect"-->
<!--            :placeholder="$t('Condition')"-->
<!--            class="mr-4"-->
<!--            width="100px"-->
<!--        />-->
<!--        <ws-text-field-->
<!--            class="mr-4"-->
<!--            :placeholder="$t('EnterValue')"-->

<!--        />-->

<!--        <v-btn icon>-->
<!--          <v-icon :color="wsDARKLIGHT">mdi-delete-outline</v-icon>-->
<!--        </v-btn>-->

<!--      </div>-->

<!--      <ws-button block outlined label="Додати умову"></ws-button>-->
<!--    </v-sheet>-->


    <!-- CONTENT-->

  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";
import finemailer from "@/mixins/finemailer";

export default {
  name: "emailReceiversGroupDialog",
  mixins : [finemailer],
  props : {
    value : {
      type : Boolean
    },
    newsletterId : {
      type : String
    },
    subtract : {
      type : Boolean,
      default: false
    }
  },
  data() {
    return {
      display : false,
      entityData : {},
      contactLists : [],

    }
  },
  computed : {
    filtersKeysSelect() {
      let items = [
        { text : this.$t('Country')  , value : 'country' , type : 'text' },
        { text : this.$t('Language') , value : 'language', type : 'select' , items : [
            { text : 'ua' , value : 'ua' },
            { text : 'ru' , value : 'ru' },
            { text : 'en' , value : 'en' }
          ] },
        { text : this.$t('Tag') , value : 'tag' , type : 'select' },
        { text : this.$t('Tag') , value : 'tag' , type : 'select' }
      ]

      return items
    },
    filtersConditionsSelect() {
      let items = [
        { text : '=' , value : '=' },
        { text : '!=' , value : '=' },
        { text : '>' , value : '>' },
        { text : '<' , value : '<' },
        { text : '>=' , value : '>=' },
        { text : '<=' , value : '<=' }
      ]

      return items
    },
    receiversGroupFilterCondition() {
      return this.entityData.type === 'course'
    },
    receiversGroupFiltersSelect() {
      let items =  [
        { text : 'filter' , value : 'filter' }
      ]

      return items
    },
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('finemailer' , [
      'ADD_EDIT_NEWSLETTER',
      'GET_NEWSLETTER',
      'ADD_RECEIVERS_GROUP',
      'DELETE_RECEIVERS_GROUP'
    ]),
    ...mapActions('contacts' , [
      'GET_CONTACT_LISTS',
    ]),

    handleSegmentationOn(value) {
      if (value && !this.entityData.filters) {
        this.entityData.filters = [
          { type : null , condition : null , value : null }
        ]
        this.entityData = this.COPY(this.entityData)
      }
    },

    async handleSave() {
      this.entityData.newsletter_id = this.newsletterId
      this.entityData.is_subtract = this.subtract
      let result = await this.ADD_RECEIVERS_GROUP(this.entityData)
      if ( !result ) {
        return this.ERROR()
      }
      this.$emit('save' ,  result)
      this.display = false

    }
  },
  async mounted() {
    if ( this.contactLists.length === 0)  {
      this.contactLists = (await this.GET_CONTACT_LISTS())['items'] || [{text : 'bauzal' , value : 'pedro'}]
    }
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>
</style>