<template>
  <ws-dialog
      v-model="display"
      :title="$t('Preview')"
      @save="$emit('save')"
      height="100%"
      width="95%"
      max-width="1046px"
      not-centered
      hide-buttons
  >

    <email-viewer
        :entity="entity"
        :uuid="uuid"
    />



    <!-- CONTENT-->

  </ws-dialog>
</template>

<script>
import EmailViewer from "@/components/pages/businessDashboard/fineMailer/emailConstructor/viewer/EmailViewer.vue";
export default {
  name: "emailPreviewDialog",
  components : {
    EmailViewer
  },
  props : {
    value : {
      type : Boolean
    },
    entity : {
      type : Object,
      default () { return {} }
    },
    uuid : {
      type : String
    },
  },
  data() {
    return {
      display : false
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>
