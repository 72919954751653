<template>
<div >

  <!-- Button Style-->
  <v-sheet class="mb-5" :style="`border : 1px solid ${wsBACKGROUND}; border-radius : 8px`">
    <!-- Primary Style-->
    <div @click="changeButtonStyle('primary')" class="pointer d-flex align-center py-4" >

      <ws-check-box
          @prevent="changeButtonStyle('primary')"
          prevent-select radio :selected-color="wsACCENT" :value="element.config.style === 'primary'"  :small="false" :color="wsACCENT" class="mr-1 ml-2" />
      <v-sheet
          :color="wsATTENTION"
          class="d-flex align-center justify-center mr-3 wsRoundedLight"
          min-width="84" min-height="42"
          dark
      >
        <h3 :style="`color : white`" >
          Ab
          <v-icon color="white" v-if="element.config.display_icon">
            {{ element.config.icon }}
          </v-icon>
        </h3>
      </v-sheet>
      <h5 :style="`color : ${wsACCENT}`">{{ $t('ButtonPrimary') }}</h5>
    </div>
    <v-divider :style="`border-color : ${wsBACKGROUND}`" />
    <!-- Secondary Style-->
    <div
        @click="changeButtonStyle('secondary')"
        class="pointer d-flex align-center py-4" >

      <ws-check-box
          :value="element.config.style === 'secondary' "
          :selected-color="wsACCENT"
          :color="wsACCENT"
          @prevent="changeButtonStyle('secondary')"
          :small="false"
          class="mr-1 ml-2"
          prevent-select radio
      />
      <v-sheet
          :style="`border : 2px solid ${wsACCENT}`"
          class="d-flex align-center justify-center mr-3 wsRoundedLight"
          min-width="84" min-height="42"
      >
        <h3 :style="`color : ${wsACCENT}`">
          Ab
          <v-icon :color="wsACCENT" v-if="element.config.display_icon">
            {{ element.config.icon }}
          </v-icon>
        </h3>
      </v-sheet>
      <h5 :style="`color : ${wsACCENT}`">{{ $t('ButtonSecondary') }} </h5>
    </div>
    <v-divider :style="`border-color : ${wsBACKGROUND}`" />
    <!-- Text Style-->
    <div
        @click="changeButtonStyle('text')"
        class="pointer d-flex align-center py-4" >

      <ws-check-box
          @prevent="changeButtonStyle('text')"
          :value="element.config.style === 'text' "
          :selected-color="wsACCENT"
          :color="wsACCENT"
          :small="false"
          prevent-select radio
          class="mr-1 ml-2"
      />
      <v-sheet
          color="transparent"
          class="d-flex align-center mr-3"
          min-width="84" min-height="42"
      >
        <h3 :style="`color : ${wsACCENT}`" class="text-no-wrap">Ab
          <v-icon :color="wsACCENT" v-if="element.config.display_icon">
            {{ element.config.icon }}
          </v-icon>
        </h3>
      </v-sheet>
      <h5 :style="`color : ${wsATTENTION}`">{{ $t('Link') }} </h5>
    </div>
  </v-sheet>

  <a-parameter-switch
      v-if="element.config.style !== 'text'"
      @input="$store.state.finemailer.elementEditTrigger++"
      v-model="$store.state.finemailer.selectedElement.config.full_width"
      label="format.full_width"
      class="mb-6"
      light
  />

  <ws-text-field
      v-model="$store.state.finemailer.selectedElement.content"
      @change="$store.state.finemailer.elementEditTrigger++"
      :label="$t('Text')"
      :placeholder="$t('ButtonText')"
      clearable
      avalon-style
  />

  <ws-select
      @input="$store.state.finemailer.elementEditTrigger++"
      v-model="$store.state.finemailer.selectedElement.config.action"
      :items="buttonActions"
      :label="$t('Action')"
      :placeholder="$t('ChooseButtonAction')"
      class="mt-5 mb-5"
      avalon-style
  />

  <ws-text-field
      v-if="element.config.action === 'link'"
      v-model="$store.state.finemailer.selectedElement.config.url"
      @change="$store.state.finemailer.elementEditTrigger++"
      :label="$t('Link')"
      placeholder="https://"
      clearable
      avalon-style

  />

</div>
</template>

<script>
export default {
  name: "emailSettingsGeneral",
  computed : {
    element() {
      return this.$store.state.finemailer.selectedElement || {}
    },
    buttonActions() {
      return [
        { text : this.$t('OpenLink') , value : 'link' },
        { text : this.$t('GoToDashboard') , value :  'dashboard' },
      ]
    }
  },
  methods : {
    changeButtonStyle(value) {
      this.$store.state.finemailer.selectedElement.config.style = value
      this.$store.state.finemailer.selectedElement = this.COPY(this.$store.state.finemailer.selectedElement)
      this.$store.state.finemailer.elementEditTrigger++
    },
  }
}
</script>

<style scoped>

</style>