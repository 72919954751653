<template>
  <v-sheet
      :style="`border-top : 1px solid ${wsBACKGROUND} !important`"
      class="d-flex flex-row  "
  >

    <v-sheet class="pl-4 py-4" color="transparent" width="150">
      <ws-button
          @click="$emit('back')"
          :color="wsACCENT"
          label="GoBack"
          outlined
      />
    </v-sheet>

    <div class="flex-grow-1 fill-height">

      <div class="d-flex justify-center align-center fill-height">

        <template v-for="(item , index) in  items" >
          <v-sheet
              :key="index"
              class="fill-height d-flex flex-column" color="transparent">
            <div style="height: 4px"></div>

            <div class="flex-grow-1 d-flex align-center">
              <h5 @click="gotoStep(index)"
                  :class="[{pointer : (index  <=  value) }]"
                  :style="`color : ${index  <=  value  ? wsATTENTION : wsDARKLIGHT}`">
                {{ item.text }}
              </h5>
            </div>

            <div :style="`background :  ${value === index  ? wsATTENTION : 'transparent'};`"
                 style="border-top-left-radius:4px; border-top-right-radius: 4px; height: 4px"
            />

          </v-sheet>

          <v-sheet
              v-if="index  < items.length - 1"
              :key="index"
              color="transparent" width="48" class="d-flex justify-center align-center">
            <v-icon :color="index + 1 <= value  ? wsATTENTION : wsDARKLIGHT" >mdi-chevron-right</v-icon>
          </v-sheet>

        </template>


      </div>

    </div>

    <v-sheet  color="transparent" class="d-flex justify-end pr-4 py-4" width="150">
      <ws-button
          v-if="value === 1"
          @click="$emit('test-email')"
          :disabled="disableNext"
          label="mailer.send_test.title"
          :color="wsACCENT"
          outlined
          class="mr-2"
      />
      <ws-button
          @click="$emit('next')"
          :disabled="disableNext"
          :label="value === 2 ? 'Send' : 'Next'"
          icon="mdi-chevron-right"
          min-width="100"
      />
    </v-sheet>

  </v-sheet>
</template>

<script>
export default {
  name: "newsletterWizardFooter",
  props : {
    value : {
      type : Number,
      default : 0
    },
    disableNext : {
      type : Boolean,
      default : false
    },
    items : {
      type : Array,
      default() { return [] }
    }
  },
  data() {
    return {

    }
  },
  computed : {
  },
  watch :{
  },
  methods : {
    gotoStep(step) {
      if ( step <  this.value  ) {
        this.$emit('input' , step )
      }
    }
  }
}
</script>

<style scoped>

</style>