<template>
<div>
  <a-parameter-slider
      @change="$store.state.finemailer.elementEditTrigger++"
      v-model="$store.state.finemailer.selectedElement.config.width"
      default="100"
      :label="$t('Width')"
      :track-color="wsBACKGROUND"
      :font-color="wsDARKER"
      :value-color="wsACCENT"
      min="0"
      max="100"
      unit="%"
      light
  />

  <a-parameter-slider
      @change="$store.state.finemailer.elementEditTrigger++"
      v-model="$store.state.finemailer.selectedElement.config.size"
      default="1"
      :label="$t('Thickness')"
      :track-color="wsBACKGROUND"
      :font-color="wsDARKER"
      :value-color="wsACCENT"
      min="1"
      max="10"
      unit="px"
      light
  />

  <avalon-color-picker
      :value="$store.state.finemailer.selectedElement.config.color || EMAIL_CONFIG_COLOR_PARAM('line') "
      @input="$store.state.finemailer.selectedElement.config.color = $event; $store.state.finemailer.elementEditTrigger++"
      label="avalon.color_styles.line_color"
      :color-function="EMAIL_COLOR"
      class="my-5"
      font-regular
      divider
  />


</div>
</template>

<script>
import avalonColorPicker from "@/components/AvalonEditor/DesigSystemUI/AvalonColorPicker";
import finemailer from "@/mixins/finemailer";
export default {
  name: "emailSettingsGeneral",
  mixins : [finemailer],
  components : {
    avalonColorPicker
  },
  computed : {}
}
</script>

<style scoped>

</style>