<template>
  <ws-dialog
      v-model="display"
      :title="$t('mailer.send_test.title')"
      :save-text="$t('Send')"
      :disable-save="!email"
      @save="sendTestEmail"
  >

    <h3>uuid : {{ uuid }}</h3>
    <ws-text-field
      v-model="email"
      label="Email"
      placeholder="test@example.ua"
    />

    <!-- CONTENT-->

  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "emailPreviewDialog",
  props : {
    value : {
      type : Boolean
    },
    uuid : {
      type : String
    }
  },
  data() {
    return {
      display : false,
      email : ''
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('finemailer' , ['SEND_TEST_EMAIL']),

    async sendTestEmail( ) {
      const data = {
        newsletter_id : this.uuid,
        email : this.email
      }

      let result = await this.SEND_TEST_EMAIL(data)
      if ( !result ) {
        return this.ERROR()
      }
      this.notify(this.$t('mailer.send_test.completed') , 'success')
      this.display = false
    }
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>
