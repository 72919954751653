<template>
  <div v-if="selectedBlock.elements.length > 0" class="px-5">

    <!-- Columns amount and Proportion-->
    <h4 class="mt-4 wsDARKER"  style="font-size : 14px" > {{ $t('Columns') }}</h4>
    <ws-button-group
        @input="handleColumnsTypeSelect"
        v-model="columnTypes"
        :items="columnTypesSelect"
        class="mb-6 mt-2"
        dense
    />

    <!-- Columns Padding -->
    <a-parameter-slider
        v-if="$store.state.finemailer.selectedBlock.config.columns > 1"
        @change="$store.state.finemailer.blockEditTrigger++"
        v-model="$store.state.finemailer.selectedBlock.config.column_padding"
        default="0"
        :label="$t('mailer.editor.block.columns_padding')"
        :track-color="wsBACKGROUND"
        :font-color="wsDARKER"
        :value-color="wsACCENT"
        class="pb-6"
        light
        min="0"
        max="60"
    />

    <!-- STYLES -->
    <!-- Background Color -->
    <avalon-color-picker
        v-model="$store.state.finemailer.selectedBlock.config.background_color"
        @input="$store.state.finemailer.blockEditTrigger++"
        label="mailer.editor.block.background_color"
        :color-function="EMAIL_COLOR"
        class="mb-6"
        font-regular
        divider
        clearable
    />
    <!-- Border Switch -->
    <a-parameter-switch
        @input="handleBorderChange"
        :value="$store.state.finemailer.selectedBlock.config.border > 0"
        label="mailer.editor.block.border"
        class="mt-6"
        light
    />
    <v-divider class="mt-3 mb-3" :style="`border-color : ${wsDARKLIGHT}; `" />

    <!-- Border Thickness -->
    <a-parameter-slider
        v-if="$store.state.finemailer.selectedBlock.config.border > 0"
        @change="$store.state.finemailer.blockEditTrigger++"
        v-model="$store.state.finemailer.selectedBlock.config.border"
        default="1"
        :label="$t('mailer.editor.block.border_value')"
        :track-color="wsBACKGROUND"
        :font-color="wsDARKER"
        :value-color="wsACCENT"
        light
        class="pb-6"
        min="1"
        max="10"
    />

    <!-- Border Color -->
    <avalon-color-picker
        v-if="$store.state.finemailer.selectedBlock.config.border > 0"
        v-model="$store.state.finemailer.selectedBlock.config.border_color"
        @input="$store.state.finemailer.blockEditTrigger++"
        label="mailer.editor.block.border_color"
        :color-function="EMAIL_COLOR"
        class="mb-4"
        font-regular
        divider
    />

    <!-- Rounded Value -->
    <a-parameter-slider
        v-if="!!$store.state.finemailer.selectedBlock.config.border  || !!$store.state.finemailer.selectedBlock.config.background_color"
        @change="$store.state.finemailer.blockEditTrigger++"
        v-model="$store.state.finemailer.selectedBlock.config.border_radius"
        default="0"
        :label="$t('Rounded')"
        :track-color="wsBACKGROUND"
        :font-color="wsDARKER"
        :value-color="wsACCENT"
        class="pb-6"
        min="0"
        max="200"
        light
    />


    <h4 class="mt-4" style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('Padding') }}</h4>
    <ws-button-group
        v-model="paddingType"
        @input="handlePaddingTypeChange"
        :items="PADDING_SELECT"
        class="mb-2 mt-2"
        dense
    />
    <div v-if="paddingType === 'custom' ">
      <a-parameter-slider
          @change="$store.state.finemailer.blockEditTrigger++"
          v-model="$store.state.finemailer.selectedBlock.config.padding_top"
          default="0"
          :label="$t('PaddingTop')"
          :track-color="wsBACKGROUND"
          :font-color="wsDARKER"
          :value-color="wsACCENT"
          class="pb-6"
          min="0"
          max="50"
          light
      />
      <a-parameter-slider
          @change="$store.state.finemailer.blockEditTrigger++"
          v-model="$store.state.finemailer.selectedBlock.config.padding_bottom"
          default="0"
          :label="$t('PaddingBottom')"
          :track-color="wsBACKGROUND"
          :font-color="wsDARKER"
          :value-color="wsACCENT"
          class=" pb-6"
          min="0"
          max="50"
          light
      />
      <a-parameter-slider
          @change="$store.state.finemailer.blockEditTrigger++"
          v-model="$store.state.finemailer.selectedBlock.config.padding_left"
          default="0"
          :label="$t('PaddingLeft')"
          :track-color="wsBACKGROUND"
          :font-color="wsDARKER"
          :value-color="wsACCENT"
          class=" pb-6"
          min="0"
          max="50"
          light
      />
      <a-parameter-slider
          @change="$store.state.finemailer.blockEditTrigger++"
          v-model="$store.state.finemailer.selectedBlock.config.padding_right"
          default="0"
          :label="$t('PaddingRight')"
          :track-color="wsBACKGROUND"
          :font-color="wsDARKER"
          :value-color="wsACCENT"
          class=" pb-6"
          min="0"
          max="50"
          light
      />
    </div>


    <h4 class="mt-4" style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('Margin') }}</h4>
    <a-parameter-slider
        @change="$store.state.finemailer.blockEditTrigger++"
        v-model="$store.state.finemailer.selectedBlock.config.margin_top"
        default="0"
        :label="$t('PaddingTop')"
        :track-color="wsBACKGROUND"
        :font-color="wsDARKER"
        :value-color="wsACCENT"
        class="pb-6"
        min="0"
        max="200"
        light
    />
    <a-parameter-slider
        @change="$store.state.finemailer.blockEditTrigger++"
        v-model="$store.state.finemailer.selectedBlock.config.margin_bottom"
        default="0"
        :label="$t('PaddingBottom')"
        :track-color="wsBACKGROUND"
        :font-color="wsDARKER"
        :value-color="wsACCENT"
        class="pb-6"
        min="0"
        max="200"
        light
    />


    <ws-accordion
        v-if="false"
        :items="navigationSelect"
        class="mt-5"
    >
      <template #item.paddings>
        <div class="pa-5">
        </div>
      </template>


    </ws-accordion>

  </div>
  <email-block-templates-selector
      v-else
  />
</template>

<script>
import {mapState} from "vuex";
import emailBlockTemplatesSelector
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/editor/settings/UI/emailBlockTemplatesSelector";
import avalonColorPicker from "@/components/AvalonEditor/DesigSystemUI/AvalonColorPicker";
import finemailer from "@/mixins/finemailer";

export default {
  name: "emailSettingsGeneral",
  mixins : [finemailer],
  components : {
    avalonColorPicker,
    emailBlockTemplatesSelector
  },
  data() {
    return {
      paddingType : null,
      marginType : null,
      columnTypes : '1',
      columnsSelect : [
        { text: 1, value: 1 },
        { text: 2, value: 2 },
        { text: 3, value: 3 },
      ],
      columnTypesSelect : [
        { ws_icon: 'columns-single', value: '1' },
        { ws_icon: 'columns-two', value: '2' },
        { ws_icon: 'columns-two-2d3', value: '2/3' },
        { ws_icon: 'columns-two-1d3', value: '1/3' },
        { ws_icon: 'columns-three', value: '3' },

      ]
    }
  },
  computed : {
    ...mapState('finemailer' , [
      'selectedBlock'
    ]),
    navigationSelect() {
      return [
        { name : this.$t('Paddings') , value : 'paddings' ,expanded : true },
      ]
    },

    blockUuid() {
      return this.selectedBlock.uuid
    },
    backgorundTypesSelect() {
      return [
        { text : this.$t('None') , value : null },
        { text : this.$t('Background') , value : 'bg' },
      ]
    }
  },
  watch : {
    blockUuid() {

      this.getPaddingType()
    }
  },
  methods : {
    handleBorderChange(value) {
      this.$store.state.finemailer.selectedBlock.config.border = value ?  1 : 0
      this.$store.state.finemailer.selectedBlock = this.COPY(this.$store.state.finemailer.selectedBlock)
      this.$store.state.finemailer.blockEditTrigger++
    },

    handleColumnsTypeSelect(value) {
      switch (value) {
        case '1' :
          this.$store.state.finemailer.selectedBlock.config.columns = 1
          this.$store.state.finemailer.selectedBlock.config.proportion_half = 50
          break;
        case '2' :
          this.$store.state.finemailer.selectedBlock.config.columns = 2
          this.$store.state.finemailer.selectedBlock.config.proportion_half = 50
          break;
        case '2/3' :
          this.$store.state.finemailer.selectedBlock.config.columns = 2
          this.$store.state.finemailer.selectedBlock.config.proportion_half = 65
          break;
        case '1/3' :
          this.$store.state.finemailer.selectedBlock.config.columns = 2
          this.$store.state.finemailer.selectedBlock.config.proportion_half = 35
          break;
        case '3' :
          this.$store.state.finemailer.selectedBlock.config.columns = 3
          this.$store.state.finemailer.selectedBlock.config.proportion_half = 50
          break;
      }

      this.$store.state.finemailer.selectedBlock = this.COPY(this.$store.state.finemailer.selectedBlock)
      this.$store.state.finemailer.blockEditTrigger++

    },
    handlePaddingTypeChange(type) {
      let size = this.PADDING_SIZES.find(el => el.name === type)

      if (size) {

        this.$store.state.finemailer.selectedBlock.config.padding_top = size.value
        this.$store.state.finemailer.selectedBlock.config.padding_bottom = size.value
        this.$store.state.finemailer.selectedBlock.config.padding_left = size.value
        this.$store.state.finemailer.selectedBlock.config.padding_right = size.value
        this.$store.state.finemailer.selectedBlock = this.COPY(this.$store.state.finemailer.selectedBlock)
        this.$store.state.finemailer.blockEditTrigger++
      }

    },
    getPaddingType() {

      this.PADDING_SIZES.forEach(size => {

        if (   this.selectedBlock.config.padding_top === size.value
            && this.selectedBlock.config.padding_bottom === size.value
            && this.selectedBlock.config.padding_left === size.value
            && this.selectedBlock.config.padding_right === size.value
        ) {
          this.paddingType =  size.name
          return
        }
      })

      if ( !this.selectedBlock.config.padding_top
          && !this.selectedBlock.config.padding_bottom
          && !this.selectedBlock.config.padding_left
          && !this.selectedBlock.config.padding_right
      ) {
        this.paddingType =  null
        return
      }

    },
  },
  mounted() {
    this.getPaddingType()
  }
}
</script>

<style scoped>

</style>