<template>
  <div :style="`border : 1px solid ${wsBACKGROUND}`" class="wsRoundedSemi overflow-hidden">

    <!-- Blank Email-->
    <v-sheet v-if="blank" class="d-flex align-center justify-center" height="314">
      <v-icon :color="wsBACKGROUND" size="100">mdi-assistant</v-icon>
    </v-sheet>

    <!-- Preview Email-->
    <v-sheet v-else height="314" class="d-flex align-center justify-center">
      <ws-logo />
    </v-sheet>

    <!-- Description Block-->
    <v-sheet
        :color="wsLIGHTCARD"
        :style="`border-top: 1px solid ${wsBACKGROUND}`"
        class="pa-4 d-flex flex-column"
        min-height="170"
    >

      <h4 class="wsDARKER">{{ !blank ? (entity.name || $t('Title')) :  $t('mailer.templates.blank.title') }}</h4>
      <h5 class="wsACCENT font-weight-regular shorten-text-two mt-1 mb-4">{{  !blank ? entity.description || $t('Description') :  $t('mailer.templates.blank.description') }}</h5>

      <div class="flex-grow-1"/>

      <div class="d-flex">
        <ws-button @click="select(entity.value)" label="Choose" outlined/>
        <ws-button
            v-if="!blank"
            @click="preview(entity.value)" label="Preview" :color="wsACCENT" text
        />
      </div>


    </v-sheet>


  </div>
</template>

<script>
export default {
  name: "emailTemplateCard",
  props : {
    entity : {
      type : Object,
      default() { return {} }
    },
    blank : {
      type : Boolean,
      default : false
    }
  },
  methods : {
    select(value) {
      value = !this.blank ? value : null
      this.$emit('select' , value)
    },
    preview(value) {
      this.$emit('preview' , value)
    }
  }
}
</script>

<style scoped>

</style>