<template>
  <newsletterTypeDialog
      v-if="!newsletterType"
      v-model="display"
      @select="($event) => {newsletterType = $event}"
  />
  <ws-dialog
      v-else
      persistent
      v-model="display"
      :title="$t('avalon.website.setup.title')"
      blank
      fullscreen
      :background-color="wsLIGHTCARD"
      no-navigation
      not-centered
      no-padding
  >

    <template #header>
      <portal-target name="finemailer_wizzard_header">
      </portal-target>
    </template>

    <template #default>

      <newsletter-wizard-templates
        v-if="step === 0"
        @select="createNewsletter"
        :newsletter-type="newsletterType"
        @close="display = false"
      />
      <email-constructor
          v-if="step === 1"
          :uuid="newsletterDesignId"
          :newsletter-id="entityData.uuid"
          @close="display = false"
      />
      <newsletter-wizard-settings
          v-if="step === 2"
          :newsletter-type="newsletterType"
          :newsletter="entityData"
          @close="display = false"
      />

      <ws-dialog
          v-if="displaySendDialog"
          v-model="displaySendDialog"
          :title="'sendNewsletter'"
          @save="startNewsletter"
          save-text="Send"
      >
        <h1>This is your moment of glory!</h1>
      </ws-dialog>


    </template>

    <template #footer>
      <wizard-navigation-footer
          v-model="step"
          @test-email="$store.state.finemailer.displayTestEmailTrigger++"
          @back="prevStep"
          @next="nextStep"
          :items="navigationItems"
          @step-name="stepName = $event"
          :disable-next="disableNextCondition"
      />
    </template>

  </ws-dialog>

</template>

<script>
import newsletterTypeDialog
  from "@/components/pages/businessDashboard/fineMailer/newsletterWizard/newsletterTypeDialog";
import newsletterWizardSettings
  from "@/components/pages/businessDashboard/fineMailer/newsletterWizard/newsletterWizardSettings";
import newsletterWizardTemplates
  from "@/components/pages/businessDashboard/fineMailer/newsletterWizard/newsletterWizardTemplates";
import EmailConstructor from "@/components/pages/businessDashboard/fineMailer/emailConstructor/EmailConstructor";
import wizardNavigationFooter
  from "@/components/pages/businessDashboard/fineMailer/newsletterWizard/UI/wizardNavigationFooter";
import  {mapState,mapActions} from "vuex";


export default {
  name: "NewsletterWizard",
  components : {
    newsletterTypeDialog,
    newsletterWizardSettings,
    newsletterWizardTemplates,
    EmailConstructor,
    wizardNavigationFooter
  },
  props : {
    value : {
      type : Boolean
    },
    newsletter : {
      type : Object,
      default() { return {} }
    },
    single : {
      type : Boolean,
      default : false
    },
    auto : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      display : false,
      newsletterType : null,
      step : 0,
      stepName : null,
      entityData : {},
      newsletterDesignId : null,
      displaySendDialog : false,
    }
  },
  computed : {
    ...mapState('finemailer' , ['triggerSendNewsletter']),

    disableNextCondition() {
      if ( !this.newsletterDesignId ) {
        return true
      }
      // if ( this.step === 1 ) {
      //   return true
      // }
      return false
    },

    navigationItems() {
      return [
        { text : this.$t('Templates'), value : 'templates' },
        { text : this.$t('Content'), value : 'content' },
        { text : this.$t('Settings'), value : 'settings' },
      ]
    }
  },
  watch : {
    triggerSendNewsletter() {
      this.displaySendDialog = true
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('finemailer' , [
      'ADD_EDIT_NEWSLETTER',
      'GET_NEWSLETTER',
      'START_NEWSLETTER'
    ]),

    async startNewsletter() {
      this.notify('I WILL SEND')
      let result = await this.START_NEWSLETTER(this.entityData.uuid)
      if (!result) {
        return this.ERROR(this.$store.state.ajax.error)
      }
      this.notify('Newsletter Started' , 'success')
    },

    async createNewsletter(template) {
      let data = {
        type : this.newsletterType,
        template : template,
        name : this.$t('Draft')
      }
      let result = await this.ADD_EDIT_NEWSLETTER(data)
      if ( !result ) {
        return this.ERROR()
      }
      this.entityData = result
      this.newsletterDesignId = result.newsletter_design_id
      this.$emit('create' , result)

      this.step = 1
    },

    async initPage() {
      if ( !this.newsletter.uuid ) {
        return
      }

      this.entityData = (await this.GET_NEWSLETTER(this.newsletter.uuid)) ?? this.newsletter
      this.newsletterType = this.newsletter.is_automation ? 'auto' : 'single'
      this.newsletterDesignId = this.newsletter.newsletter_design_id
      this.step = 1

    },

    // technical
    prevStep() {
      if (this.step > 0) {
        this.step--
      }
    },
    nextStep() {
      if ( this.step === 2) {
        this.$store.state.finemailer.triggerSendNewsletter++
        return
      }
      this.step++
    },
  },
  beforeMount() {
    if ( this.single) {
      this.newsletterType = 'single'
    }
    if ( this.auto) {
      this.newsletterType = 'auto'
    }
  },
  mounted() {
    this.initPage()
    if ( this.value ) {
      this.display = this.value
    }

  }
}
</script>

<style scoped>

</style>