<template>
<div class="fill-height d-flex flex-row " >

  <!-- Header-->
  <portal to="finemailer_wizzard_header">
    <v-sheet :color="wsDARKLIGHT" dark class="d-flex align-center justify-space-between " style="padding : 6px 16px">

      <div></div>

      <h5 class="d-flex align-center">
        <v-icon class="mr-2">mdi-bookmark-outline</v-icon>
        {{ $t('SaveTemplate') }}
      </h5>

      <!-- Preview Modes and Close-->
      <div class="d-flex align-center">


        <!-- Screen size -->
        <v-sheet
            :color="wsBACKGROUND"
            :style="`border: 2px solid ${wsBACKGROUND} !important`"
            class="wsRoundedLight d-flex"
        >
          <ws-tooltip :text="$t('DesktopView')">
            <v-icon
                v-ripple
                @click="toggleMobileView(false)"
                :style="`background: ${!EMAIL_MOBILE_VIEW ? wsATTENTION : wsBACKGROUND}`"
                style=" border-top-left-radius: 3px; border-bottom-left-radius: 3px"
                :color="!EMAIL_MOBILE_VIEW ? 'white' : wsACCENT"
                class="px-2 pointer">
              mdi-laptop
            </v-icon>
          </ws-tooltip>

          <v-divider vertical :style="`border-color : ${wsBACKGROUND}`"></v-divider>
          <ws-tooltip :text="$t('MobileView')">
            <v-sheet   v-ripple class="fill-height" :color="EMAIL_MOBILE_VIEW ? wsATTENTION : wsBACKGROUND">
              <v-icon
                  @click="toggleMobileView(true)"

                  style="border-top-right-radius: 3px; border-bottom-right-radius: 3px"
                  size="18"
                  :color="EMAIL_MOBILE_VIEW ? 'white' : wsACCENT"
                  class="px-3 pointer">
                mdi-cellphone
              </v-icon>
            </v-sheet>
          </ws-tooltip>
        </v-sheet>

        <v-btn @click="openPreview" icon >
          <v-icon>mdi-eye</v-icon>
        </v-btn>

        <v-btn @click="$emit('close')" icon >
          <v-icon>mdi-close</v-icon>
        </v-btn>

      </div>



    </v-sheet>
  </portal>

  <!-- Main Content-->
  <v-sheet
      @click="deselectElements"
      class="flex-grow-1 fill-height justify-center overflow-y-auto" :color="EMAIL_CONFIG_COLOR('background')">
    <email-editor
        :uuid="uuid"
        @update-design="newsletterDesign = $event"
    />
  </v-sheet>

  <!-- SideBar -->
  <v-sheet class="fill-height" width="320">
    <email-editor-settings />
  </v-sheet>


  <email-preview-dialog
      v-if="displayPreview"
      v-model="displayPreview"
      :entity="newsletterDesign"
  />
  <email-send-test-dialog
      v-if="displaySendTest"
      v-model="displaySendTest"
      :uuid="newsletterId"
  />


</div>
</template>

<script>
import newsletterConfig from '@/assets/json/finemailer/newsletterConfig.json'
import EmailEditor from "@/components/pages/businessDashboard/fineMailer/emailConstructor/editor/EmailEditor";
import emailEditorSettings
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/editor/settings/EmailEditorSettings";
import finemailer from "@/mixins/finemailer";
import emailPreviewDialog
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/viewer/emailPreviewDialog";
import emailSendTestDialog
  from "@/components/pages/businessDashboard/fineMailer/newsletterWizard/UI/emailSendTestDialog";
import {mapState} from "vuex";
export default {
  name: "EmailConstructor",
  mixins : [finemailer],
  props : {
    uuid : {
      type : String
    },
    newsletterId : {
      type : String
    }
  },
  components : {
    EmailEditor,
    emailEditorSettings,
    emailPreviewDialog,
    emailSendTestDialog
  },
  data() {
    return {
      displayPreview : false,
      displaySendTest : false,
      newsletterDesign : {}
    }
  },
  computed : {
    ...mapState('finemailer' , ['displayTestEmailTrigger'])
  },
  watch : {
    displayTestEmailTrigger() {
      this.displaySendTest = true
    }
  },
  methods : {
    openPreview() {
      this.displayPreview = true
    },
    toggleMobileView(value) {
      this.$store.state.finemailer.mobileView = value
    },
    deselectElements() {
      this.$store.state.finemailer.selectedElement = {config : {}}
      this.$store.state.finemailer.selectedBlock = {config : {}}
      this.$store.state.finemailer.navigation = 'general'
    }
  },
  beforeMount() {
    this.$store.state.finemailer.defaultNewsletterConfig = newsletterConfig
    this.PREPARE_EMAIL_CONFIG()
    this.$store.commit('finemailer/FLUSH_DATA')
  },
}
</script>

<style scoped>

</style>