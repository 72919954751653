<template>
<div class="pa-5">

  <!-- Buttons and Images Settings  -->

  <emailSettingsButton
      v-if="selectedElement.type === 'button'"
      class="mb-5"
  />
  <emailSettingsImage
      class="mb-5"
      v-if="selectedElement.type === 'image'"
      top
  />

  <email-settings-line
      v-if="selectedElement.type === 'line'"
  />

  <!-- Horizontal Alignment -->
  <div v-if="selectedElement.config.width !== 100 && selectedElement.type !== 'file'">
    <h4   style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('Align') }}</h4>
    <ws-button-group
        @input="$store.state.finemailer.elementEditTrigger++"
        v-model="$store.state.finemailer.selectedElement.config.align"
        :items="ALIGNMENT_SELECT"
        class="mb-2 mt-2"
    />
  </div>


  <!-- Text Elements Settings  -->

  <emailSettingsImage
      v-if="selectedElement.type === 'image'"
  />
  <email-settings-text
      class="mt-5"
      v-if="['heading','text'].includes(selectedElement.type) "
  />

  <email-settings-file
      v-if="selectedElement.type === 'file'"
  />


  <!-- Margin Settings  -->
  <h4 class="mt-4" style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('Margin') }}</h4>
  <a-parameter-slider
      @change="$store.state.finemailer.elementEditTrigger++"
      v-model="$store.state.finemailer.selectedElement.config.margin_top"
      default="0"
      :label="$t('PaddingTop')"
      :track-color="wsBACKGROUND"
      :font-color="wsDARKER"
      :value-color="wsACCENT"
      class="pb-6"
      min="0"
      max="200"
      light
  />
  <a-parameter-slider
      @change="$store.state.finemailer.elementEditTrigger++"
      v-model="$store.state.finemailer.selectedElement.config.margin_bottom"
      default="0"
      :label="$t('PaddingBottom')"
      :track-color="wsBACKGROUND"
      :font-color="wsDARKER"
      :value-color="wsACCENT"
      class="pb-6"
      min="0"
      max="200"
      light
  />

</div>
</template>

<script>
import {mapState} from "vuex";

import emailSettingsText
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/editor/settings/elements/emailSettingsText";
import emailSettingsButton
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/editor/settings/elements/emailSettingsButton";
import emailSettingsImage
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/editor/settings/elements/emailSettingsImage";
import emailSettingsLine
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/editor/settings/elements/emailSettingsLine";
import emailSettingsFile
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/editor/settings/elements/emailSettingsFile";
import finemailer from "@/mixins/finemailer";

export default {
  name: "emailSettingsGeneral",
  mixins : [finemailer],
  components : {
    emailSettingsText,
    emailSettingsButton,
    emailSettingsImage,
    emailSettingsLine,
    emailSettingsFile
  },
  computed : {
    ...mapState('finemailer' , [
      'selectedElement',
    ]),
    navigationSelect() {
      return [
        { name : this.$t('Margin') , value : 'margin' },
      ]
    },
  }
}
</script>

<style scoped>

</style>