<template>

  <div>
    <!-- Title-->
    <textarea
        v-model="entityData.name"
        @input="adjustTextareaHeight"
        @change="editNewsletter"
        @keydown.enter="editNewsletter"
        ref="text"
        type="text "
        class="wsDARKER"
        :placeholder="$t('EnterText')"
        style="width: 100%; font-size: 22px; font-weight: 600; resize: none; outline: none;"
        rows="1"
    />
    <!-- Settings Accordion-->
    <div class="wsRoundedSemi overflow-hidden" :style="`border : 1px solid ${wsBACKGROUND}`">
      <div v-for="(item,i) in settingsSelect" :key="i"
           :style="i < (settingsSelect.length - 1) ? `border-bottom : 1px solid ${wsBACKGROUND}` : null"

      >
        <!-- Accordion Headings -->
        <v-hover #default="{hover}">
          <v-sheet :color="hover ? wsLIGHTCARD : 'transparent'  " class="d-flex align-center justify-space-between pa-4">
            <div class="d-flex align-center">
              <v-icon :color="item.isSetup ? wsSUCCESS : wsBACKGROUND" class="mr-4">mdi-check-circle</v-icon>
              <div>
                <h4 class="wsDARKER">
                  {{ item.name }}
                </h4>
                <h5 v-if="item.setup_pre_text" class="wsACCENT mb-1">
                  {{ item.setup_pre_text }}
                </h5>
                <h5 class="wsACCENT font-weight-regular">
                  {{ item.isSetup ? item.setup_text : item.subtitle }}
                </h5>
              </div>

            </div>

            <ws-button
                @click="handleExpand(item.value)"
                style="border-radius: 100px"
                :label-raw="navigation === item.value ? $t('Hide') : (item.isSetup ? $t('Edit') : item.editText)"
                :color="wsATTENTION"
                text
            >
            </ws-button>
          </v-sheet>
        </v-hover>

        <!-- Content -->
        <v-expand-transition>
          <div :key="item.value" v-if="navigation === item.value"
               :style="`border-top : 1px solid ${wsBACKGROUND}`"
          >
            <div class="pa-6" >

              <!-- Receivers Single-->
              <div v-if="navigation === 'receivers'" :class="[{'py-10' : entityData.newsletter_groups.length === 0  }]">

                <ws-data-table
                    v-if="entityData.newsletter_groups.length > 0"
                    :items="entityData.newsletter_groups"
                    :headers="receiversHeaders"
                    disable-pagination
                    no-header
                    no-footer
                    style="border : 1px solid var(--wsBACKGROUND); border-radius: 4px"
                >
                  <template #item.type="{item}">
                    <div class="d-flex align-center justify-space-between">
                      <h5 class="d-flex align-center font-weight-regular wsDARKER">
                        <v-icon :color="wsACCENT" class="mr-2">mdi-{{ !item.is_subtract ? 'plus' : 'minus' }}-circle-outline</v-icon>
                        (0)  •  {{ getReceiverGroupName(item.type) }}
                        <span v-if="item.contact_list_id"> / {{ item.contact_list_name }}</span>
                        <span v-if="item.course_id"> / {{ item.course_name }}</span>
                      </h5>
                      <v-btn :disabled="LOADING" :color="wsACCENT" @click="deleteReceiverGroup(item.uuid)" icon>
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </div>


                  </template>
                </ws-data-table>

                <div v-else>
                  <div class="d-flex justify-center">
                    <v-icon size="74" :color="wsDARKLIGHT">mdi-account-multiple</v-icon>
                  </div>
                  <h4 class="font-weight-regular text-center pb-3 wsACCENT"> {{  $t('mailer.wizard.settings.receivers.no_data')  }}</h4>

                </div>

                <div class="d-flex" :class="[{'justify-center' : entityData.newsletter_groups.length === 0 }]">
                  <ws-button
                      class="mt-4 mr-2"
                      @click="openEditReceivers(false)"
                      label="mailer.wizard.settings.receivers.add"
                      left-icon="mdi-plus"
                  />
                  <ws-button
                      class="mt-4"
                      @click="openEditReceivers(true)"
                      label="mailer.wizard.settings.receivers.subtract"
                      left-icon="mdi-minus"
                      :color="wsACCENT"
                      outlined
                  />
                </div>


              </div>

              <!-- Sender -->
              <div v-if="navigation === 'sender'">
                Sender will be herere
              </div>

              <!-- Subject -->
              <div v-if="navigation === 'subject'">
                <ws-text-field
                    v-model="subject"
                    :label="$t('mailer.wizard.settings.subject.title')"
                    :placeholder="$t('mailer.wizard.settings.subject.placeholder')"
                    max-chars="150"
                />
                <ws-text-field
                    v-model="previewText"
                    :label="$t('mailer.wizard.settings.subject.preview')"
                    :placeholder="$t('mailer.wizard.settings.subject.preview_placeholder')"
                    class="mt-6"
                />
                <h5 class="wsACCENT font-weight-regular mt-3 mb-6">{{ $t('mailer.wizard.settings.subject.preview_description') }}</h5>
                <div class="d-flex justify-end">
                  <ws-button @click="saveSubject" :disabled="saveSubjectDisable" class="mt-6" label="Save" />
                </div>

              </div>

              <!-- Receivers Single-->
              <div v-if="navigation === 'time'">

                <v-row>
                  <v-col cols="6">

                    <ws-select
                        @input="handleDateChange"
                        v-if="sendingDate !== 'choose'"
                        :items="sendingDateSelect"
                        v-model="sendingDate"
                        :label="$t('mailer.wizard.settings.time.date_title')"
                        :placeholder="$t('mailer.wizard.settings.time.date_placeholder')"
                    />
                    <ws-date-picker
                        v-else
                        v-model="sendingDateValue"
                        :label="$t('mailer.wizard.settings.time.date_title')"
                        :placeholder="$t('mailer.wizard.settings.time.date_placeholder')"
                        @clear="sendingDate = 'today'"
                        :expand-trigger="datePickerTrigger"
                        clearable
                    />
                  </v-col>
                  <v-col cols="6">
                    <ws-select
                        :items="sendingTimeSelect"
                        v-model="sendingTime"
                        :label="$t('mailer.wizard.settings.time.time_title')"
                        :placeholder="$t('mailer.wizard.settings.time.time_placeholder')"
                    />

                  </v-col>

                </v-row>
                <div class="d-flex justify-end">
                  <ws-button @click="saveDate" :disabled="saveSubjectDisable"  label="Save" />
                </div>
              </div>

            </div>

          </div>
        </v-expand-transition>

      </div>
    </div>

    <!-- Edit Receivers Groups -->
    <email-receivers-group-dialog
        v-if="displayEditReceivers"
        v-model="displayEditReceivers"
        :newsletter-id="entityData.uuid"
        :subtract="subtractReceiverGroup"
        @save="addReceiverGroup"
    />

  </div>




</template>

<script>
import {mapActions} from "vuex";
import emailReceiversGroupDialog
  from "@/components/pages/businessDashboard/fineMailer/newsletterWizard/emailSettingsAccordion/emailReceiversGroupDialog";
import finemailer from "@/mixins/finemailer";

export default {
  name: "emailSettingsAccordion",
  mixins : [finemailer],
  components : {
    emailReceiversGroupDialog
  },
  props : {
    items : {
      type : Array,
      default() { return [] }
    },
    newsletter : {
      type : Object,
      default() { return {} }
    },
  },
  data() {
    return {
      entityData : {},
      navigation : null,
      subject : null,
      previewText : null,
      sendingDate : null,
      sendingTime : null,
      datePickerTrigger : 0,
      sendingDateValue : null,

      displayEditReceivers : false,

      receiversEntityData : {},
      receiversHeaders : [{text : 'type' , value : 'type'}],
      subtractReceiverGroup : false
    }
  },
  computed : {
    sendingDateSelect() {
      return [
        { text : this.$t('Today') , value : 'today' },
        { text : this.$t('Tomorrow') , value : 'tomorrow' },
        { text : this.$t('ChooseDate') , value : 'choose' }
      ]
    },
    sendingTimeSelect() {
      let items = [];
      let startHour = 0;
      for (let i = startHour; i < 24; i++) {
        const hour = i < 10 ? `0${i}` : i
        items.push(
            { text : `${hour}:00` , value : `${hour}:00` },
            { text : `${hour}:30` , value : `${hour}:30` }
        )
      }
      return items
    },
    saveSubjectDisable() {

      if ( this.subject !== this.entityData.subject) {
        return false
      }
      if ( this.previewText !== this.entityData.preview_text) {
        return false
      }

      return true
    },
    settingsSelect() {
      let items = [

        { name : this.$t('mailer.wizard.settings.receivers.title') ,
          subtitle : this.$t('mailer.wizard.settings.receivers.subtitle'),
          editText: this.$t('mailer.wizard.settings.receivers.add'),
          value : 'receivers',
          isSetup : this.entityData.newsletter_groups.length > 0
        },
        { name : this.$t('From') ,
          subtitle : this.$t('mailer.wizard.settings.sender.subtitle'),
          editText: this.$t('Edit'),
          setup_pre_text : this.BUSINESS_EMAIL_CLIENT_ADDRESS ,
          setup_text : this.BUSINESS_NAME ,
          value : 'sender',
          isSetup : true
        },
        { name : this.$t('mailer.wizard.settings.subject.title') ,
          subtitle : this.$t('mailer.wizard.settings.subject.subtitle'),
          editText: this.$t('mailer.wizard.settings.subject.add'),
          value : 'subject',
          isSetup : !!this.entityData.subject,
          setup_pre_text : this.entityData.subject,
          setup_text : this.entityData.preview_text
        },
        { name : this.$t('mailer.wizard.settings.time.title') ,
          subtitle : this.$t('mailer.wizard.settings.time.subtitle'),
          editText: this.$t('mailer.wizard.settings.time.add'),
          value : 'time',
          isSetup : false
        },

      ]

      return items
    }
  },
  methods : {
    ...mapActions('finemailer' , [
      'ADD_EDIT_NEWSLETTER',
      'GET_NEWSLETTER',
      'ADD_RECEIVERS_GROUP',
      'DELETE_RECEIVERS_GROUP'
    ]),

    async addReceiverGroup(item){
      this.entityData.newsletter_groups.push(item)
    },
    async deleteReceiverGroup(uuid){
      let result = await this.DELETE_RECEIVERS_GROUP(uuid)
      if ( !result ) {
        return this.ERROR()
      }

      let index = this.entityData.newsletter_groups.findIndex(el => el.uuid === uuid)
      if ( index === -1 ) {
        return
      }
      this.entityData.newsletter_groups.splice(index , 1)
      this.entityData = this.COPY(this.entityData)
    },

    openEditReceivers(subtractReceivers = false) {
      this.subtractReceiverGroup = subtractReceivers
      this.displayEditReceivers = true
    },
    saveDate() {
      this.sendingDateValue
    },

    handleDateChange(value) {
      if ( value === 'choose') {
        setTimeout(()=>{
          this.datePickerTrigger++
        },10)

      }
    },
    openPicker() {
      this.datePickerTrigger++
    },
    saveSubject() {
      this.entityData.subject = this.subject
      this.entityData.preview_text = this.previewText
      this.entityData = this.COPY(this.entityData)
      this.editNewsletter()
    },
    async editNewsletter() {
      let result = await this.ADD_EDIT_NEWSLETTER(this.entityData)
      if ( !result ) {
        return this.ERROR()
      }
      this.entityData = result
      this.$emit('edit' , result)
    },

    // Technical
    getReceiverGroupName(type) {
      let item = this.RECEIVERS_GROUP_TYPES_SELECT.find(el => el.value === type)
      return item ? item.text : type
    },
    adjustTextareaHeight() {
      const input = this.$refs.text;
      input.style.height = "auto";
      if ( this.entityData.name ) {
        input.style.height = input.scrollHeight + "px";
      }
    },
    handleExpand(value) {
      this.navigation = this.navigation === value ? null : value
    }
  },
  mounted() {
    this.entityData = this.COPY(this.newsletter)
    this.subject = this.entityData.subject
    this.previewText = this.entityData.preview_text
  }
}
</script>

<style scoped>

</style>