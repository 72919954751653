<template>
<div class="pa-5">
  <h3 class="wsDARKER mb-5">Оберіть блок із шаблонів</h3>
  <v-hover #default="{hover}" v-for="block in items" :key="block">
    <v-sheet  :color="!hover ? wsLIGHTCARD : wsACCENT"
             :dark="hover"
              :style="`border : 1px solid ${wsACCENT}`"
             class="wsRoundedLight pointer d-flex align-center justify-center mb-4" >
      <email-block-view
          :uuid="block"
      />
    </v-sheet>
  </v-hover>

</div>
</template>

<script>
import {mapActions} from "vuex";
import emailBlockView from "@/components/pages/businessDashboard/fineMailer/emailConstructor/viewer/emailBlockView.vue";
export default {
  name: "emailBlockTemplatesSelector",
  components : { emailBlockView },
  data() {
    return {
      items : []
    }
  },
  methods : {
    ...mapActions('finemailer' , [
      'GET_NEWSLETTER_BLOCKS_TEMPLATES',
    ]),
    async initPage() {
      this.items = (await this.GET_NEWSLETTER_BLOCKS_TEMPLATES())
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>