<template>
<div class="px-8 pb-8">


  <portal to="finemailer_wizzard_header">
    <div class="px-8 py-6 d-flex justify-space-between">

      <ws-logo  :title="!SM ? `mailer.wizard.${newsletterType}.title` : null"/>

      <h3 style="font-size: 22px" class="wsDARKER">{{ $t('mailer.templates.choose') }}</h3>

      <v-sheet color="transparent" class="d-flex justify-end" style="width: 150px">
        <v-btn @click="$emit('close')" icon :color="wsACCENT">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-sheet>

    </div>
    <div class="d-flex justify-center mb-4">
      <ws-button-group
          :items="navigationItems"
          v-model="category"
      />
    </div>
  </portal>


  <v-row>

    <v-col v-for="(item,index) in itemsFiltered" :key="index" cols="4" lg="3">
      <email-template-card
          @select="$emit('select' , $event)"
          :entity="item"
          :blank="item.isBlank"
      />
    </v-col>

  </v-row>

</div>
</template>

<script>
import emailTemplateCard
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/templates/emailTemplateCard";

export default {
  name: "newsletterWizardTemplates",
  components : {
    emailTemplateCard
  },
  props : {
    newsletterType : {
      type : String,
      default : 'single'
    }
  },
  data() {
    return {
      category : null,
      items : [
        { name : 'Промокод на загальну знижку' ,
          description : 'Реклмний лист із промокодом на загальну знижку на всі курси',
          value : 'promo123'
        },
        { name : 'Промокод на курс' ,
          description : 'Реклмний лист із промокодом на знижку на певний курс',
          value : 'promo123'
        },
        { name : 'Оголошення про знижки' ,
          description : 'Рекламне оголошення початку знижок на всі курси',
          value : 'promo123'
        },
        { name : 'Оголошення про знижку на курс' ,
          description : 'Рекламне оголошення початку знижок на всі курси',
          value : 'promo123'
        },
      ]
    }
  },
  computed : {
    itemsFiltered() {
      let items = this.items

      items.unshift({ isBlank : true })

      return items
    },
    navigationItems() {
      return [
        { text : this.$t('All') , value : null },
        { text : this.$t('Saved') , value : 'saved' },
        { text : this.$t('WeStudy') , value : 'westudy' }
      ]
    }
  }
}
</script>

<style scoped>

</style>